<template>
  <div class="flex flex-grow flex-col min-w-0 lg:ml-20 lg:mr-8 overflow-hidden">
    <slot></slot>
    <!-- valid meeting ID -->
    <div class="flex-grow mt-5">
      <m-card class="flex flex-col mb-4">
        <div
          class="flex justify-between cursor-default pt-7 bg-white dark:bg-base5 lg:pt-0 sticky top-8 lg:top-0 z-20 sticky"
        >
          <!-- Meeting title header -->
          <div class="flex items-center min-w-0 bg-white dark:bg-base5">
            <div>
              <div class="flex flex-col lg:flex-row lg:items-baseline">
                <m-placeholder
                  class="ml-3 h-7 rounded"
                  style="width: 12rem"
                ></m-placeholder>
                <m-placeholder
                  class="ml-3 h-5 rounded mt-2 lg:mt-0"
                  style="width: 7rem"
                ></m-placeholder>
                <m-placeholder
                  class="hidden lg:flex ml-3 h-5 rounded"
                  style="width: 7rem"
                ></m-placeholder>
              </div>
            </div>
          </div>
          <div class="hidden lg:flex mr-3 mt-1">
            <div v-for="i in 4" :key="i">
              <m-placeholder class="ml-1 rounded-full w-7 h-7"></m-placeholder>
            </div>
          </div>
        </div>

        <div class="flex flex-grow flex-col my-2 dark:text-base1">
          <!-- Menu -->
          <div
            class="flex sticky top-28 lg:top-8 bg-white dark:bg-base5 w-full z-10 mb-3 lg:mb-0 blur-content"
          >
            <div
              class="flex w-full bg-white dark:bg-base5 pt-1 lg:pt-0  lg:ml-3 mt-2 mb-1"
            >
              <div class="flex">
                <div
                  class="flex transition-opacity duration-500 ml-2 lg:ml-0 w-full lg:w-auto"
                >
                  <button type="button" class="formattingbutton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="editorbutton stroke-current"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="4" y="4" width="16" height="16" rx="2" />
                      <path d="M9 12l2 2l4 -4" />
                    </svg>
                    <!-- Online indicator -->
                    <span
                      class="h-2 w-2 absolute ping"
                      style="margin-top:-0.3rem;margin-left:-0.8rem;"
                    >
                      <span
                        class="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-alertwarning opacity-75"
                        style="margin-top:0.47rem;"
                      ></span>
                      <span
                        class="relative inline-flex rounded-full h-2 w-2 bg-alertwarning"
                      ></span>
                    </span>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      class="editorbutton fill-current"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0H24V24H0z" />
                        <path
                          d="M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0H24V24H0z" />
                        <path
                          d="M4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 4c2.071 0 3.75 1.679 3.75 3.75 0 .857-.288 1.648-.772 2.28l-.148.18L18.034 18H22v2h-7v-1.556l4.82-5.546c.268-.307.43-.709.43-1.148 0-.966-.784-1.75-1.75-1.75-.918 0-1.671.707-1.744 1.606l-.006.144h-2C14.75 9.679 16.429 8 18.5 8z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0H24V24H0z" />
                        <path
                          d="M22 8l-.002 2-2.505 2.883c1.59.435 2.757 1.89 2.757 3.617 0 2.071-1.679 3.75-3.75 3.75-1.826 0-3.347-1.305-3.682-3.033l1.964-.382c.156.806.866 1.415 1.718 1.415.966 0 1.75-.784 1.75-1.75s-.784-1.75-1.75-1.75c-.286 0-.556.069-.794.19l-1.307-1.547L19.35 10H15V8h7zM4 4v7h7V4h2v16h-2v-7H4v7H2V4h2z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                        />
                      </g>
                    </svg>
                  </button>

                  <button type="button" class="formattingbutton">
                    <svg
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                        />
                      </g>
                    </svg>
                  </button>
                  <button type="button" class="formattingbutton">
                    <svg
                      viewBox="0 0 24 24"
                      class="editorbutton fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          d="M16.9,7.1c-0.4-0.4-1-0.4-1.4,0l-8.4,8.4c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l8.4-8.4C17.3,8.1,17.3,7.5,16.9,7.1z"
                        />
                        <path
                          d="M8.5,22.5L12,19c1.4-1.4,1.8-3.5,1.1-5.3l-1.7,1.7c0,0.8-0.2,1.6-0.9,2.3l-3.5,3.5c-1.2,1.2-3.1,1.2-4.2,0   c-1.2-1.2-1.2-3.1,0-4.2l3.5-3.5c0.6-0.6,1.5-0.9,2.3-0.9l1.7-1.7C8.5,10.2,6.4,10.5,5,12l-3.5,3.5c-1.9,1.9-1.9,5.1,0,7   C3.4,24.5,6.6,24.5,8.5,22.5z"
                        />
                        <path
                          d="M13.4,6.4l3.5-3.5c1.2-1.2,3.1-1.2,4.2,0s1.2,3.1,0,4.2l-3.5,3.5c-0.6,0.6-1.5,0.9-2.3,0.9l-1.7,1.7   c1.8,0.7,3.9,0.3,5.3-1.1l3.5-3.5c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L12,5c-1.4,1.4-1.8,3.5-1.1,5.3l1.7-1.7   C12.5,7.8,12.8,7,13.4,6.4z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>

              <!-- Meeting buttons: agenda, icebreaker, assistant -->
              <div class="fixed lg:static -right-24 -mr-6 lg:flex lg:mt-1 z-50">
                <div class="relative">
                  <m-button
                    class="my-2 mx-2 cursor-default blur-content"
                    button-style="btn-tertiary"
                    icon="📅"
                    >Agenda</m-button
                  >
                </div>

                <div class="relative">
                  <div class="text-xs">
                    <m-button
                      class="my-2 mx-2 cursor-default blur-content"
                      button-style="btn-tertiary"
                      icon="🧊"
                    >
                      Icebreaker
                    </m-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Menu END -->
          <span v-for="i in 4" :key="i">
            <m-placeholder
              class="ml-3 h-5 rounded w-11/12 my-2"
            ></m-placeholder>
          </span>
          <m-placeholder class="ml-3 h-5 rounded w-6/12 my-2"></m-placeholder>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div class="flex justify-between">
          <m-button
            class="self-end m-3 cursor-default blur-content"
            button-style="btn-primary"
            icon="✉️"
          >
            Send
          </m-button>
        </div>
      </m-card>

      <div class="blur-content">
        <div class="flex mt-6 ml-5 lg:ml-0">
          <m-heading>Time machine</m-heading>
        </div>
        <hr
          class="border-solid border-l-0 border-r-0 border-b-0 -mt-4 ml-28 lg:ml-24 border-base2 border-opacity-5"
        />
        <div class="flex flex-col mt-5">
          <!-- Related meeting -->
          <div v-for="i in 2" :key="i" class="flex-grow">
            <m-card class="mb-4">
              <div class="flex justify-between min-w-0">
                <div class="lg:flex items-center my-1 min-w-0">
                  <div class="flex items-baseline flex-col lg:flex-row">
                    <m-placeholder
                      class="ml-3 h-7 rounded"
                      style="width: 12rem"
                    ></m-placeholder>
                    <m-placeholder
                      class="ml-3 h-5 rounded mt-2 lg:mt-0"
                      style="width: 7rem"
                    ></m-placeholder>
                    <m-placeholder
                      class=" hidden lg:flex ml-3 h-5 rounded"
                      style="width: 7rem"
                    ></m-placeholder>
                  </div>
                </div>

                <div class="hidden lg:flex mt-1 mr-3 ">
                  <div v-for="i in 4" :key="i">
                    <m-placeholder
                      class="ml-1 rounded-full w-7 h-7"
                    ></m-placeholder>
                  </div>
                </div>
              </div>
            </m-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/components/UI/MButton';
import MCard from '@/components/UI/MCard';
import MHeading from '@/components/UI/MHeading';
import MPlaceholder from '@/components/UI/MPlaceholder';

export default {
  name: 'BlurredMeeting',
  components: {
    MButton,
    MCard,
    MHeading,
    MPlaceholder,
  },
};
</script>

<style scoped>
.formattingbutton {
  @apply outline-none focus:outline-none flex-grow cursor-default;
}
.editorbutton {
  @apply h-6 opacity-25 inline rounded mr-2 my-auto;
  height: 0.95rem;
}
/* .editorbutton:hover {
  @apply opacity-50;
} */

.blur-content {
  filter: blur(1px);
}
</style>
